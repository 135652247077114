import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Testimonial from "../../../Testimonial"

const content = {
  quote:
    "ReferralCandy is the only app that lets us flexibly customize our design at low cost, and to pay cash to customers who refer.",
  cite: "DapperTime",
}

const query = graphql`
  query {
    showcase: file(
      relativePath: { eq: "images/testimonials/picture-dappertime.png" }
    ) {
      childImageSharp {
        fixed(width: 305) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    avatar: file(
      relativePath: { eq: "images/testimonials/avatar-dappertime.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default () => {
  const { showcase, avatar } = useStaticQuery(query)
  return <Testimonial showcase={showcase} avatar={avatar} content={content} />
}
