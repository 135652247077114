import React from "react"
import Layout from "../components/Layout"
import List from "../components/Sections/CaseStudies/List"

import BikiniBodTestimonial from "../components/Sections/CaseStudies/BikiniBodTestimonial"

import Industries from "../components/Sections/CaseStudies/Industries"
import DapperTimeTestimonial from "../components/Sections/CaseStudies/DapperTimeTestimonial"

const metadata = {
  title: "ReferralCandy Case Studies - Referral Marketing Customer Examples",
}

const CaseStudiesPage = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <List />
      <BikiniBodTestimonial />
      <Industries />
      <DapperTimeTestimonial />
    </Layout>
  )
}

export default CaseStudiesPage
