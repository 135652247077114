import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Testimonial from "../../../Testimonial"

const content = {
  quote:
    "ReferralCandy is amaaazing. It has driven sales and provided our Ambassadors incentive to get the word about our brand out there.",
  cite: "Bikini Bod",
}

const query = graphql`
  query {
    showcase: file(
      relativePath: { eq: "images/testimonials/picture-bikini-bod.png" }
    ) {
      childImageSharp {
        fixed(width: 305) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    avatar: file(
      relativePath: { eq: "images/testimonials/avatar-bikini-bod.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default () => {
  const { showcase, avatar } = useStaticQuery(query)
  return <Testimonial showcase={showcase} avatar={avatar} content={content} />
}
