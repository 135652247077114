import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import css from "./index.module.scss"
import Grid from "../../../Grid"
import Button from "../../../Button"

const query = graphql`
  query {
    picture_audphones: file(
      relativePath: { eq: "images/laptop-audphones.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Industries = () => {
  const { picture_audphones } = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <h2>Built for all types of businesses</h2>
        <Button el={Link} to="/referral-programs">
          View all industries
        </Button>
      </div>
      <div className={css.image}>
        <Img
          fluid={picture_audphones.childImageSharp.fluid}
          alt="audphone laptop"
        />
      </div>
    </Grid>
  )
}

export default Industries
