import React from "react"
import css from "./index.module.scss"
import Grid from "../../../Grid"
import Button from "../../../Button"
import { graphql, Link, useStaticQuery } from "gatsby"

import SAINSTORE_LOGO from "../../../../assets/images/case-studies/sain-store-logo-white-small.png"

const SLUG_TO_IMAGE_MAPPING = {
  "/case-studies/riff-raff/":
    "//cdn.referralcandy.com/webimages/logos/retailers/riffraff+logo.png",
  "/case-studies/ztylus/":
    "//cdn.referralcandy.com/webimages/logos/retailers/ztylus-logo.png",
  "/case-studies/dappertime/":
    "//cdn.referralcandy.com/webimages/logos/retailers/dappertime-logo.png",
  "/case-studies/videoblocks/":
    "//cdn.referralcandy.com/webimages/logos/retailers/videoblocks-logo.png",
  "/case-studies/22-days-nutrition/":
    "//cdn.referralcandy.com/webimages/logos/retailers/22daysnutrition-logo.jpg",
  "/case-studies/okabashi/":
    "//cdn.referralcandy.com/webimages/logos/retailers/okabashi-logo.png",
  "/case-studies/leesa/":
    "//cdn.referralcandy.com/webimages/logos/retailers/leesa-logo.png",
  "/case-studies/critical-pass/":
    "//cdn.referralcandy.com/webimages/logos/retailers/critical-pass.jpg",
  "/case-studies/contact-lens-online/":
    "//cdn.referralcandy.com/webimages/case-studies/contact-lens/contact-lens-logo.jpg",
  "/case-studies/magical-butter/":
    "//cdn.referralcandy.com/webimages/logos/retailers/magicalbutter-logo.png",
  "/case-studies/miaomiao/":
    "//www.referralcandy.com/case-studies/miaomiao-logo.png",
  "/case-studies/ledger/":
    "//www.referralcandy.com/case-studies/ledger-logo.png",
  "/case-studies/a-box-of-stories/":
    "//www.referralcandy.com/case-studies/abos-logo.png",
  "/case-studies/love-yourself-diet/":
    "//www.referralcandy.com/case-studies/loveyourself-logo.png",
  "/case-studies/christy-dawn/":
    "//www.referralcandy.com/case-studies/christy-dawn-logo.png",
  "/case-studies/branch-basics/":
    "//www.referralcandy.com/case-studies/branch-basics-logo.png",
  "/case-studies/mac-of-all-trades/":
    "//www.referralcandy.com/case-studies/mac-of-all-trades_logo.jpeg",
  "/case-studies/evolve-skateboards/":
    "//www.referralcandy.com/case-studies/evolve-skateboards-logo.jpeg",
  "/case-studies/do-it-center/":
    "//www.referralcandy.com/case-studies/do-it-center-logo.png",
  "/case-studies/momomi/":
    "//www.referralcandy.com/case-studies/momomi_logo.jpeg",
  "/case-studies/renogy/":
    "//www.referralcandy.com/case-studies/renogy-logo.jpeg",
  "/case-studies/cycleboard/":
    "//www.referralcandy.com/case-studies/cycleboard-logo.png",
  "/case-studies/dmd-skin-sciences/":
    "//www.referralcandy.com/case-studies/dmd-skin-sciences-logo.png",
}

const List = () => {
  const data = useStaticQuery(caseStudyQuery)
  const { allMdx } = data
  const { edges } = allMdx

  const listItems = edges.map((n) => {
    const mdx = n.node
    return (
      <li>
        <img
          src={SLUG_TO_IMAGE_MAPPING[mdx.fields.slug]}
          alt={mdx.frontmatter.title}
          height="300"
          width="300"
        />
        <p>{mdx.frontmatter.title}</p>
        <Button el={Link} to={mdx.fields.slug}>
          Read Case Study
        </Button>
      </li>
    )
  })

  const sainstoreCaseStudy = (
    <li>
      <img src={SAINSTORE_LOGO} alt="Sainstore Logo" height="300" width="300" />
      <p>How SainStore grew sales for their client with ReferralCandy</p>
      <Button
        href="https://partners.referralcandy.com/case-study-sainstore"
        target="_blank"
      >
        Read Case Study
      </Button>
    </li>
  )
  const partnerListItems = [sainstoreCaseStudy]

  return (
    <Grid className={css.container}>
      <h1 className={css.header}>
        Join 30,000+ online stores who have trusted ReferralCandy
      </h1>
      <div className={css.text}>
        <ul className={css.items}>
          {listItems}
          {partnerListItems}
        </ul>
      </div>
    </Grid>
  )
}

export default List

const caseStudyQuery = graphql`
  query CaseStudiesQuery {
    allMdx(
      sort: { fields: [frontmatter___priority], order: DESC }
      filter: { fileAbsolutePath: { regex: "/case-studies/" } }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
